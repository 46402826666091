<template>
  <div class="friendRequest">
    <!-- <div class="title">
              <img src="@/assets/bracelet_img/7.png" mode="">
              <span>好友请求</span>
          </div>
          <div class="blackBar">
              <div class="greenBar mar_10"></div>
              <span class="mar_10">一</span>
              <span class="mar_10">起</span>
              <span class="mar_10">社</span>
              <span class="mar_10">交</span>
              <span class="mar_10">赚</span>
              <span>钱</span>
          </div> -->
    <watch-head :title="$t('好友请求')"
                :img="require('@/assets/bracelet_img/7.png')"></watch-head>
    <div class="content">
      <!-- <van-search
                    v-model="keyword"
                    left-icon=""
                    placeholder="请输入搜索关键词"
                    class="search"
              >
                    <template #right-icon>
                        <img class="right-icon" src="../../assets/img/6.png" mode="">
                    </template>
              </van-search> -->
      <div class="recommend" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('请求')}}</div>
      <van-list v-model="loading"
                :finished="finished"
                :class="{'vn-italic': $i18n.locale == 'vn'}"
                :finished-text="$t('没有更多了')"
                :loading-text="$t('加载中...')"
                :immediate-check="false"
                :offset="20"
                @load="onLoad"
                class="list">
        <div class="item"
             v-for="(item, index) in list"
             :key="index">
          <div class="right">
            <div class="img">
              <van-image class="image"
                         lazy-load
                         round
                         :src="item.avatar" />
              <div class="text">{{ item.level_no }}</div>
            </div>
            <div class="info">
              <div class="name" :class="{'vn-italic': $i18n.locale == 'vn'}">{{ item.nickname ? item.nickname : $t('暂无昵称') }}</div>
              <div class="Ranking" :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('排行')}} : {{ item.top }}</div>
            </div>
          </div>
          <div class="left">
            <img class="mar_right"
                 @click="handle(item.id, 1)"
                 src="@/assets/bracelet_img/9.png"
                 alt="">
            <img src="@/assets/bracelet_img/10.png"
                 @click="handle(item.id, 2)"
                 alt="">
          </div>
        </div>
      </van-list>
    </div>
    <div class="btm">
      <div @click="refresh"
           class="refresh pointer" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('刷新')}}</div>
      <div class="request_add">
        <div class="left">
          <div class="box"
               @click="goBack">
            <img src="@/assets/bracelet_img/5.png"
                 alt="">
          </div>
        </div>
        <div class="right">
          <van-badge class="request pointer"
                     :content="friendsnum > 0 ? friendsnum : '' "
                     max="99">
            <div :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('请求')}}</div>
          </van-badge>
          <div @click="goTo"
               class="add pointer" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('添加好友')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
   
<script>
import watchHead from '@/components/watchHead'
import { getFriendResList, handleRequest, FriendsnumApi } from '@/api/api'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      handleLoading: false,
      loading: false,
      finished: false,
      page: 1,
      list: [],
      friendsnum: 0,
    }
  },
  components: {
    watchHead,
  },
  computed: {
    ...mapState([
      'web3',
    ]),
  },
  created () { this.waitForGlobal(); },
  methods: {
    handle (id, state) {
      if (!this.handleLoading) {
        this.handleLoading = true
        handleRequest({
          id,
          state
        }).then(res => {
          if (res.code == 0) {
            this.loading = true
            this.finished = false
            this.list = []
            this.page = 1
            this.onLoad();
            this.getFriendsnum()
          }
          this.$toast(res.msg);
          this.handleLoading = false
        })
      }
    },
    refresh () {
      if (!this.loading) {
        this.loading = true
        this.finished = false
        this.list = []
        this.page = 1
        this.onLoad();
      }
    },
    onLoad () {
      getFriendResList({
        page: this.page
      }).then(res => {
        if (res.code == 0) {
          this.list = this.list.concat(res.data.data)
          this.loading = false;
          if (res.data.total <= this.page) {
            this.finished = true;
          }
          this.page++
        } else {
          this.finished = true;
        }
      })
    },
    goTo () {
      this.$router.push({
        path: "/friendAdd"
      })
    },
    /* 返回上一页 */
    goBack () {
      this.$router.go(-1);
    },
    /* 获取统计 */
    getFriendsnum () {
      FriendsnumApi().then(res => {
        if (res.code == 0) {
          this.friendsnum = res.data.friendsnum
        }
      })
    },
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress')) {
        window.clearTimeout(this.timer);
        this.loading = true
        this.finished = false
        this.list = []
        this.page = 1
        this.onLoad();
        this.getFriendsnum()
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
  }
}
</script>
   
<style lang="less" scoped>
// div{
// 	box-sizing: border-box;
// }
.friendRequest {
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 60px;
  background-image: url(~@/assets/bracelet_img/8.png);
  background-repeat: repeat-y;
  background-size: 100% 100%;
  color: #ffffff;
  //   .title {
  //     padding: 0 2vw;
  //     background-color: #6480f3;
  //     display: flex;
  //     align-items: center;
  //     font-family: '071-SSRuiFengTi';
  //     font-size: 12vw;
  //     margin-bottom: 1vw;
  //     img {
  //       width: 9vw;
  //       height: 9vw;
  //       margin-right: 2vw;
  //     }
  //   }
  //   .blackBar {
  //     // padding: 2rpx;
  //     background-color: #2b2b2b;
  //     display: flex;
  //     align-items: center;
  //     font-family: 'TsangerYuYangT-W03';
  //     font-size: 3vw;
  //     .greenBar {
  //       width: 5vw;
  //       height: 3vw;
  //       background-color: #a7f264;
  //     }
  //     .mar_10 {
  //       margin-right: 1vw;
  //     }
  //   }
  .content {
    padding: 20px 16px 0;
    .search {
      padding: 0;
      border-radius: 4px;
      margin-bottom: 28px;
      .van-field {
        padding: 4px 12px 4px 0;
      }
      /deep/.van-field__right-icon {
        display: flex;
        align-items: center;
      }
      .right-icon {
        width: 28px;
        height: 28px;
      }
    }
    .recommend {
      font-family: '071-SSRuiFengTi';
      font-size: 32px;
      margin-bottom: 16px;
    }
    .list {
      height: 41.5vh;
      overflow: auto;

      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10 */
      .item {
        width: 100%;
        background-image: linear-gradient(
            237deg,
            rgba(118, 233, 214, 0.83) 0%,
            rgba(159, 174, 230, 0.83) 100%
          ),
          linear-gradient(#ffffff, #ffffff);
        background-blend-mode: normal, normal;
        border-radius: 8px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4px;
        .right {
          display: flex;
          align-items: center;
          width: 76%;
          .img {
            width: 64px;
            min-width: 64px;
            height: 64px;
            border-radius: 50%;
            background-color: #2b2b2b;
            border: solid 4px #2b2b2b;
            position: relative;
            margin-right: 24px;
            .image {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .text {
              width: 28px;
              height: 28px;
              line-height: 28px;
              background-color: #2b2b2b;
              border-radius: 50%;
              position: absolute;
              right: -14px;
              bottom: 0;
              text-align: center;
              font-family: 'AeroMaticsDisplayBoldItalic';
            }
          }
          .info {
            width: calc(100% - 88px);
            .name {
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 24px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .Ranking {
              font-family: 'AeroMaticsDisplayRegular';
              font-size: 16px;
            }
          }
        }
        .left {
          display: flex;
          align-items: center;
          img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }
          .mar_right {
            margin-right: 12px;
          }
        }
      }
    }
  }
  .btm {
    width: 100%;
    font-family: '071-SSRuiFengTi';
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 8px;
    .refresh {
      padding: 4px 80px;
      background-color: #8ff9f6;
      border-radius: 16px 4px 16px 4px;
      border: 2px solid #261e36;
      font-size: 32px;
      color: #2b2b2b;
      margin-bottom: 12px;
    }
    .request_add {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        width: 17%;
        background-color: rgba(43, 43, 43, 0.5);
        border-radius: 0 0 34px 0;
        padding: 8px 8px 8px 0;
        .box {
          background-color: #2b2b2b;
          border-radius: 0 0 32px 0;
          border: solid 2px #7736fe;
          opacity: 0.9;
          display: flex;
          align-items: center;
          justify-items: center;
          padding: 6px 8px 6px 8px;
          height: 100%;
          img {
            width: 26px;
            height: 18px;
          }
        }
      }
      .right {
        width: 82%;
        display: flex;
        align-items: center;
        background-color: rgba(43, 43, 43, 0.5);
        border-radius: 36px 0 0 0;
        padding: 8px 60px 8px 8px;
        text-align: center;

        .request {
          background-color: #8ff9f6;
          border-radius: 32px 4px 4px 4px;
          border: solid 2px #261e36;
          margin-right: 8px;
          width: 120px;
          font-size: 24px;
          color: #2b2b2b;
          padding: 2px 0;
        }
        .add {
          background-color: #5c65cb;
          border-radius: 4px 4px 16px 4px;
          border: solid 2px #261e36;
          width: 120px;
          font-size: 24px;
          color: #2b2b2b;
          padding: 2px 0;
        }
      }
    }
  }
}
@media screen and (min-width: 480px) {
  //在这里写非小屏幕设备的样式  -- 非手机
  .friendRequest {
    .btm {
      width: 430px;
    }
  }
}
</style>
   